import './App.css'

import React, {Component} from 'react'
import { Button, Form, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import Select from 'react-select';
import Center from 'react-center';
import AsyncSelect from 'react-select/lib/Async';
import Icon, { FontAwesome, Feather, Ionicons, MaterialIcons, SimpleLineIcons } from 'react-web-vector-icons';
import debounce from 'lodash/debounce' 
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import Amplify, { API, graphqlOperation } from "aws-amplify";

let myAppConfig = {
    // ...
    'aws_appsync_graphqlEndpoint': 'https://5sahb3m3c5by5omy2gdcvzu3u4.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'API_KEY',
    'aws_appsync_apiKey': 'da2-q5eawqjmtfdvfgp2pjx4i5djry',
    // ...
}

Amplify.configure(myAppConfig);

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

const loadOptions = async (inputValue) => {

    try{

      const results  = await API.graphql(graphqlOperation(`query{ keywords(keyword: "${inputValue}"){keyword, counter}}`));
      console.log(results);
      const data = results.data.keywords.map((value, index)=>{
        return {label: value.keyword, value: value.keyword}
      })
      console.log('data: ', JSON.stringify(data));
      return data
    }catch(error){
      
      console.log(`loadOptions error: `, error);
      
    }
};



class App extends Component {
  
  constructor(props){
    
    super(props);
    this.state = {
      selectedOption: null,
      selected: false,
      searchTerm: "",
      searchResults: []
    }
  }
  
  handleInputChange = (input) => {
    
    console.log(`handleInputChange: `, input)
    this.setState({searchTerm: input})
  }
  
  handleSearch = async (inputValue) => {
    
    console.log(`in handleSearch with `, inputValue)
    
    const results  = await API.graphql(graphqlOperation(`query{ search(keyword: "${inputValue}"){title, content}}`));
    console.log(`in result: `, JSON.stringify(results))
    this.setState({searchResults: results.data.search})
    console.log(`state: `, this.state.searchResults)
  }
  
  
  handleOnInputKeyDown = (event) => {
    console.log(`in onInputKeyDown`)
    switch (event.keyCode) {
      case 13: // ENTER
        console.log(`in enter`)
        this.setState({selected: true})
        this.handleSearch(this.state.searchTerm);
        event.preventDefault();
        break;
    }
  }

  onChange = (e) => {
    
    console.log(`in onchange`)
    // if(this.state.searchTerm == ""){
    //   this.input.focus()
    // }
    
    this.setState({searchTerm: e.target.value})
    this.handleSearch(e.target.value);
    // if(this.input){
    //   this.input.focus()
    // }
  }
  
  render() {
    return (
    <div>
      <div style={{position: "absolute", zIndex: 3, top: 0, right: 201, left: 0, backgroundColor: "#3C9DC3", color: "white", fontSize: 16, height: 40}}>
        {this.state.searchTerm &&
          <div style={{width:600}}>
            <img style={{height:36, float: "left", marginRight: 20, marginLeft: 20}} src={require('./logowhite.png')} />

                  <Input type="search" name="search" className="form-control" id="exampleEmail" placeholder="cerca un contenuto formativo" 
                    style={{borderColor: "#3C9DC3", width: 400, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} 
                    onChange={this.onChange}
                    value={this.state.searchTerm}
                    innerRef={x => {
                      this.input = x
                      // if(this.state.searchTerm.length==1){
                      //   x.focus()
                      // }
                      
                    }}
                  />

              {/*<AsyncSelect
                // cacheOptions
                //loadOptions={debounce(loadOptions, 300)}
                loadOptions={loadOptions}
                onInputChange={this.handleInputChange}
                placeholder="cerca"
                // defaultOptions
                onKeyDown={this.handleOnInputKeyDown}
                autoFocus={true}
                inputValue={this.state.searchTerm}
              />*/}
          </div>
        }
      </div>
      <div style={{position: "absolute", zIndex: 3, top: 0, height: 40, width: 200, backgroundColor: "#3C9DC3", right: 0, position: "absolute", color: "#3C9DC3", textAlign: "center"}}>
        <div style={{paddingTop: 12, color: "white", fontSize: 16}}>ACCEDI</div>
      </div>
      {!this.state.searchTerm ? (
        <Center style={{width: "100vw", height: "100vh"}}>
          <div style={{width:400}}>
            <img style={{marginLeft:100, marginBottom: 20}} src={require('./logotypoidea.png')} />
  
          {/*<AsyncSelect
            // cacheOptions
            //loadOptions={debounce(loadOptions, 300)}
            loadOptions={loadOptions}
            onInputChange={this.handleInputChange}
            placeholder="cerca"
            // defaultOptions
            onKeyDown={this.handleOnInputKeyDown}
            autoFocus={true}
            inputValue={this.state.searchTerm}
            
          />*/}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1" style={{backgroundColor: '#3C9DC3', borderWidth: 0}}>
                  <Feather  name='search' color='white' size={16} style={{float: "left"}}/>
                </span>
                <Input type="search" name="search" className="form-control" id="exampleEmail" placeholder="cerca un contenuto formativo" 
                  style={{borderColor: "#3C9DC3", width: 400, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} 
                  onChange={this.onChange}
                  value={this.state.searchTerm}
                  innerRef={x => this.input2 = x}
                />
              </div>
            </div>
          </div>
        </Center>
      ):(
        <div style={{backgroundColor: "white", marginTop: 60, marginLeft: 20, height: 600}}>
          <h2 style={{color: "#3C9DC3"}}>Risultati: </h2>
          {console.log(`searchResult: `, this.state.searchResults)}
          {this.state.searchResults.map((value, index)=>(
            <React.Fragment id={`${index}`}>
              <div style={{color: "#3C9DC3"}}>
                <Feather
                  name='book'
                  color='#3C9DC3'
                  size={16}
                  // style={{}}
                /> {value.title}</div>
              <div>
                {/*<Badge color="light">scattering</Badge>
                <Badge color="light">pm 10</Badge>
                <Badge color="light">polveri sottili</Badge>
                <Badge color="light">pensiero computazionale</Badge>
                <Badge color="light">iot</Badge>*/}
              </div>
            </React.Fragment>
          ))}
        </div>  
      )}
    </div>
  )
  }
}

export default App
